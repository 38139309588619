<template>
  <div class="card">
    <div>
      <div class="top">
        <!--        <img src="https://s1.chu0.com/src/img/png/8f/8fd48d4c10964ff39a4593f5236ccb25.png?e=1735488000&token=1srnZGLKZ0Aqlz6dk7yF4SkiYf4eP-YrEOdM1sob:P43BUFK8KgndxJlYIqxNWLtmzl8=" alt="">-->
        <p>{{ base.name }}</p>
        <span @click="toPath('/filmAssistant/info?id=', { id: base.code })"
          ><van-icon name="notes-o" /> 详情</span
        >
      </div>
      <div class="list">
        <div class="list-item lineRight">
          <div class="t">所属院线</div>
          <div>{{ base.cinemaLine }}</div>
        </div>
        <div class="list-item lineRight">
          <div class="t">法人代表</div>
          <div>{{ base.user || '/' }}</div>
        </div>
        <div class="list-item lineRight">
          <div class="t">影厅数</div>
          <div>{{ base.numberOfCinemas }}</div>
        </div>
        <div class="list-item">
          <div class="t">座位数</div>
          <div>{{ base.numberOfSeats }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'filmbase',
  props: {
    base: {
      type: Object,
      default() {
        return {
          name: 'AAA',
          code: 'BBB',
          cinemaLine: 'CCC',
          user: 'DDD',
          numberOfCinemas: 999,
          numberOfSeats: 999,
        }
      },
    },
  },
  methods: {
    toPath(path, query) {
      this.$router.push({
        path,
        query,
      })
    },
  },
}
</script>

<style scoped lang="less">
.card {
  width: 90%;
  margin: 0 auto;
  padding: 0.05rem;
  border-radius: 0.1rem;
  //box-shadow: 0 0 5px rgba(128, 128, 128, 0.46);
  color: #fff;

  .top {
    display: flex;
    align-items: center;
    img {
      flex: 1;
      width: 1rem;
      height: 1rem;
    }

    p {
      flex: 7;
    }

    a {
      position: relative;
      display: flex;
      flex: 1;
      font-size: 15px;
      color: white;
    }
  }

  .list {
    display: flex;
    font-size: 14px;
    text-align: center;

    div {
      flex: 1;

      .t {
        color: #004d6c;
      }
    }

    .lineRight::after {
      content: '';
      height: 0.5rem;
      margin: auto;
      //position: absolute;
      display: inline-block;
      transform: translateX(1.2rem) translateY(-0.7rem);
      border-right: 1px solid rgba(128, 128, 128, 0.44);
    }
  }
}
</style>
