<template>
  <div class="page">
    <p class="title">影院画像</p>
    <!-- {{datas}} -->
    <div class="number">
      <div
        class="number-item"
        @click="toPath('/cinemaPortrait/yearBoxOffice', { id: cinemaId })"
      >
        <div>本年度票房</div>
        <div>
          {{ datas.BoxOfficeNoService || '0' }}万
          <span :class="[datas.BoxOfficeCompare < 0 ? 'red' : 'green']"
            >{{ datas.BoxOfficeCompare || '0' }}万</span
          >
        </div>
      </div>
      <div
        class="number-item"
        @click="toPath('/cinemaPortrait/CinemaRank', { id: cinemaId })"
      >
        <div>所在城市票房排名</div>
        <div>
          {{ datas.CityRank || '0' }}名
          <span :class="[datas.CityRankAdd < 0 ? 'red' : 'green']"
            >{{ datas.CityRankAdd || '0' }}名</span
          >
        </div>
      </div>
      <div
        class="number-item"
        @click="toPath('/cinemaPortrait/oneScreenProduct', { id: cinemaId })"
      >
        <div>单银幕产出</div>
        <div>
          {{ (datas.AvgScreenValue / 10000).toFixed(2) || '0' }}万<span
            :class="[datas.AvgScreenValueAdd < 0 ? 'red' : 'green']"
            >{{ (datas.AvgScreenValueAdd / 10000).toFixed(2) || '0' }}万</span
          >
        </div>
      </div>
      <div
        class="number-item"
        @click="toPath('/cinemaPortrait/averageFare', { id: cinemaId })"
      >
        <div>平均票价</div>
        <div>
          {{ datas.AvgPrice || '0' }}元
          <!-- <span :class="[datas.AvgSaleBySession<0?'red':'green']">{{datas.AvgSaleBySession}}</span> -->
        </div>
      </div>
      <div
        class="number-item"
        @click="toPath('/cinemaPortrait/averagePerson', { id: cinemaId })"
      >
        <div>场均人次</div>
        <div>
          {{ datas.AvgSaleBySession || '0' }}人
          <span>{{ datas.CityAvgSaleBySession }}人</span>
        </div>
      </div>
      <div
        class="number-item"
        @click="toPath('/cinemaPortrait/fireSafety', { id: cinemaId })"
      >
        <div>消防安全等级</div>
        <div :class="['level' + datas.XfCinemaDj]">
          {{ datas.XfCinemaDj || '/' }}
        </div>
      </div>

      <!--      <div class="number-item">-->
      <!--        <div>-->
      <!--          放映质量指数-->
      <!--        </div>-->
      <!--        <div >-->
      <!--          99.7-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="number-item">-->
      <!--        <div>-->
      <!--          星级评定-->
      <!--        </div>-->
      <!--        <div class="blue">-->
      <!--          <van-rate-->
      <!--            v-model="startValue"-->
      <!--            allow-half-->
      <!--            readonly-->
      <!--            :size="22"-->
      <!--            color="#ffd21e"-->
      <!--            void-icon="star"-->
      <!--            void-color="#eee"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    datas: {
      type: Object,
      required: true,
      default() {},
    },
    cinemaId: {
      type: String,
      required: true,
      default: '',
    },
  },
  name: 'WarnInformation',
  data() {
    return {
      startValue: 4.5,
    }
  },
  methods: {
    toPath(path, query) {
      this.$router.push({
        path,
        query,
      })
    },
  },
}
</script>

<style scoped lang="less">
.page {
  //border: 1px solid #fff;
  border-radius: 0.1rem;
  width: 98%;
  margin: 0 auto;
  box-shadow: 0 0 6px 0 rgba(12, 12, 89, 0.3);
  background-color: #fff;
  padding-bottom: 0.3rem;
}
.title {
  padding: 0.15rem;
  font-size: 13px;
  color: #fff;
  background-image: linear-gradient(to right, #59aeef, #4fd9ef);
  border-radius: 0.1rem 0.1rem 0 0;
}
.number {
  //border: 1px solid #2c72f7;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 96%;
  margin: 0 auto;
  &-item {
    border: 1px solid rgba(128, 128, 128, 0.46);
    margin: 0 0 0.3rem 0;
    height: 45px;
    width: calc(95% / 2);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    padding: 0.1rem 0;
    div:first-child {
      margin: 0 auto;
      font-size: 13px;
      text-align: left;
      width: 90%;
      font-family: 'Microsoft YaHei', MicrosoftYaHeiSemibold, serif;
    }
    div:nth-child(2) {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC, serif;
      width: 90%;
    }
    span {
      font-size: 12px;
    }
  }
  .blue {
    color: #2c72f7;
  }
  .red {
    color: crimson;
    &::before {
      content: '↓';
    }
  }
  .green {
    color: forestgreen;
    &::before {
      content: '↑';
    }
  }
}
.level {
  &A {
    color: rgba(34, 152, 225, 0.61);
  }
  &B {
    color: #0059f7;
  }
  &C {
    color: #ebc007;
  }
  &D {
    color: #b9333c;
  }
  &E {
    color: #000;
  }
}
</style>
