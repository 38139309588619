<template>
  <div class="page">
    <p class="title">数智服务</p>
    <div class="number">
      <div
        class="number-item"
        @click="goPath('/cinemaAssistant/workProgress', { id: cinemaId })"
      >
        <div>
          <van-icon name="column" size="1rem" color="#1989fa" />
        </div>
        <div>办事进度</div>
      </div>
      <!--      <div class="number-item">-->
      <!--        <div>-->
      <!--          <van-icon name="coupon-o" size="1rem" color="#34cc5f" />-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          专资收缴-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cinemaId: {
      type: String,
      required: true,
      default: '',
    },
  },
  name: 'WarnInformation',
  methods: {
    goPath(path, query) {
      this.$router.push({
        path,
        query,
      })
    },
  },
}
</script>

<style scoped lang="less">
.page {
  //border: 1px solid #fff;
  border-radius: 0.1rem;
  width: 98%;
  margin: 0 auto;
  box-shadow: 0 0 6px 0 rgba(12, 12, 89, 0.3);
  background-color: #fff;
  padding-bottom: 0.3rem;
}
.title {
  padding: 0.15rem;
  font-size: 13px;
  color: #fff;
  background-image: linear-gradient(to right, #59aeef, #4fd9ef);
  border-radius: 0.1rem 0.1rem 0 0;
}
.number {
  //border: 1px solid #2c72f7;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  &-item {
    justify-content: flex-start;
    //flex: 1;
    width: calc(100% / 3);
    text-align: center;
    //justify-content: space-between;
    align-items: center;
    div:first-child {
      width: 50%;
      margin: 0 auto;
    }
    div:nth-child(2) {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC, serif;
    }
  }
}
</style>
