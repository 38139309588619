<template>
  <div class="page">
    <div class="title">预警信息</div>
    <div class="number">
      <div
        class="number-item"
        @click="goPath('/filmAssistant/warningMsg', { t: 0, id: cinemaId })"
      >
        <div>
          <span>{{ datas.JyNotReadCount }}</span>
          <span>/</span>
          <span>{{ datas.JyWarningCount }}</span>
        </div>
        <div>经营预警</div>
      </div>
      <div
        class="number-item"
        @click="goPath('/filmAssistant/warningMsg', { t: 1, id: cinemaId })"
      >
        <div>
          <span>{{ datas.XfNotReadCount }}</span>
          <span>/</span>
          <span>{{ datas.XfWarningCount }}</span>
        </div>
        <div>消防预警</div>
      </div>
      <div
        class="number-item"
        @click="goPath('/filmAssistant/warningMsg', { t: 2, id: cinemaId })"
      >
        <div>
          <span>{{ datas.YqNotReadCount }}</span>
          <span>/</span>
          <span>{{ datas.YqWarningCount }}</span>
        </div>
        <div>疫情预警</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WarnInformation',
  props: {
    datas: {
      type: Object,
      default() {},
      required: true,
    },
    cinemaId: {
      type: String,
      default: '',
      required: true,
    },
  },
  methods: {
    goPath(path, query) {
      this.$router.push({ path, query })
    },
  },
}
</script>

<style scoped lang="less">
.page {
  //border: 1px solid #fff;
  border-radius: 0.1rem;
  width: 98%;
  margin: 0 auto;
  box-shadow: 0 0 6px 0 rgba(12, 12, 89, 0.3);
  background-color: #fff;
}
.title {
  padding: 0.15rem;
  font-size: 13px;
  color: #fff;
  background-image: linear-gradient(to right, #59aeef, #4fd9ef);
  border-radius: 0.1rem 0.1rem 0 0;
}
.number {
  //border: 1px solid #2c72f7;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  &-item {
    flex: 1;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    div:first-child {
      width: 50%;
      margin: 0 auto;
      span:first-child {
        font-size: 0.8rem;
        color: #ff843a;
      }
      span:nth-child(2) {
        font-size: 30px;
        color: rgba(0, 0, 0, 0.2);
      }
    }
    div:nth-child(2) {
      font-size: 13px;
      font-family: PingFangSC, PingFang SC, serif;
    }
  }
}
</style>
