<template>
  <div class="page">
    <p class="title">竟对影院分析</p>
    <div class="number">
      <div
        class="number-item"
        @click="goPath(item.path, { cinemaId })"
        v-for="(item, index) in icons"
        :key="index"
      >
        <div>
          <!-- <van-icon name="bar-chart-o" size="1rem" color="#34cc5f" /> -->
          <van-image width="60" height="60" :src="item.icon" />
        </div>
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cinemaId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      icons: [
        //票价分析
        {
          name: '票价分析',
          icon: require('@/assets/icon/ca-sf.svg'),
          path: '/cinemaAssistant/competitorCinema/boxOffice',
        },
        //上座率分析
        {
          name: '上座率分析',
          icon: require('@/assets/icon/ca-sx.svg'),
          path: '/cinemaAssistant/competitorCinema/attendance',
        },
        //排片场次分析
        {
          name: '排片场次分析',
          icon: require('@/assets/icon/ca-sc.svg'),
          path: '/cinemaAssistant/competitorCinema/session',
        },
        // 排片效益分析
        {
          name: '排片效益分析',
          icon: require('@/assets/icon/ca-pp.svg'),
          path: '/cinemaAssistant/competitorCinema/benefit',
        },
      ],
    }
  },
  name: 'WarnInformation',
  methods: {
    /**
     * @description: 跳转页面
     * @param {*} path
     * @param {*} query
     * @return {*}
     */
    goPath(path, query) {
      console.log({ path, query })
      this.$router.push({
        path,
        query: { ...query },
      })
    },
  },
}
</script>

<style scoped lang="less">
.page {
  //border: 1px solid #fff;
  border-radius: 0.1rem;
  width: 98%;
  margin: 0 auto;
  box-shadow: 0 0 6px 0 rgba(12, 12, 89, 0.3);
  background-color: #fff;
  padding-bottom: 0.3rem;
}
.title {
  padding: 0.15rem;
  font-size: 13px;
  color: #fff;
  background-image: linear-gradient(to right, #59aeef, #4fd9ef);
  border-radius: 0.1rem 0.1rem 0 0;
}
.number {
  //border: 1px solid #2c72f7;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  &-item {
    width: 33.33%;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    div:first-child {
      width: 50%;
      margin: 0 auto;
    }
    div:nth-child(2) {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC, serif;
    }
  }
}
</style>
