<!--
 * @Author: kevinzgai zhanggai_ok@126.com
 * @Date: 2023-11-27 10:41:01
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-08-23 17:52:31
 * @FilePath: \zyt-mobile-frontend\src\views\cinemaAssistant\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="page">
    <div class="navbar">
      <div></div>
      <div>
        <p class="selectCinema" @click="showcinema = !showcinema">
          {{ selectCinema.text }}
          <van-icon name="arrow-down" />
        </p>
      </div>
      <!--      <div @click="goPath('/filmAssistant/info?id='+selectCinema.code)">-->
      <div>
        <!--        更多-->
      </div>
    </div>
    <div>
      <cinema-base :base="cinemaBase"></cinema-base>
      <warn-information
        :cinema-id="selectCinema.code"
        :datas="warningInfo"
      ></warn-information>
      <public-service :cinema-id="selectCinema.code"></public-service>
      <private-service :cinema-id="selectCinema.code"></private-service>
      <cinema-portrait
        :cinema-id="selectCinema.code"
        :datas="kpiInfo"
      ></cinema-portrait>
      <cinema-analyze
        v-if="showCinemaCompare"
        :cinema-id="selectCinema.code"
      ></cinema-analyze>
    </div>
    <van-popup
      v-model="showcinema"
      round
      position="center"
      :style="{ width: '90%', height: '50%' }"
    >
      <van-picker
        title="影院切换"
        :columns="cinemaList"
        show-toolbar
        @confirm="changeCinema"
      >
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import cinemaBase from './modules/index/cinemabase'
import warnInformation from '@views/cinemaAssistant/modules/index/WarnInformation'
import publicService from '@views/cinemaAssistant/modules/index/publicService'
import privateService from '@views/cinemaAssistant/modules/index/privateService'
import cinemaPortrait from '@views/cinemaAssistant/modules/index/cinemaPortrait'
import cinemaAnalyze from '@views/cinemaAssistant/modules/index/cinemaAnalyze'
import { Dialog, Toast } from 'vant'
import { zjApi as api } from '@/api/index'

export default {
  name: 'index',
  components: {
    cinemaBase,
    warnInformation,
    publicService,
    privateService,
    cinemaPortrait,
    cinemaAnalyze,
  },
  data() {
    return {
      showcinema: false,
      selectCinema: {},
      cinemaBase: {},
      warningInfo: {},
      kpiInfo: {},
      cinemaList: [],
      // 是否显示影院竞对
      showCinemaCompare: false,
    }
  },
  mounted() {
    this.getCinemaList().then((r) => {
      const upSelectCinema = JSON.parse(
        localStorage.getItem('selectCinema'),
      ) || { text: '', code: '', topShow: 0 }
      console.log({ upSelectCinema })
      if (this.cinemaList.find((r) => r.text == upSelectCinema.text)) {
        this.changeCinema(upSelectCinema)
      } else {
        const selectCinema =
          this.cinemaList.filter((s) => s.topShow === 1)[0] ||
          this.cinemaList[0]
        this.changeCinema(selectCinema)
      }
    })
  },
  methods: {
    // 更新用户可管理的影城列表
    getCinemaList() {
      return new Promise((resolve, reject) => {
        const cinemaList = localStorage.getItem('cinemaList')
        if (cinemaList) {
          // console.log({ cinemaList });
          this.cinemaList = JSON.parse(cinemaList)
          this.cinemaList = JSON.parse(cinemaList).map((c) => {
            return {
              text: c.CinemaName,
              code: c.CinemaCode,
              topShow: c.TopShow,
            }
          })
          // console.log(this.cinemaList);
          resolve(true)
        } else {
          Dialog.alert({
            title: '系统提示',
            message: '对不起，您当前无权限访问此功能，请于管理员联系！',
          }).then(() => {
            // on close
            // TODO 后台调通之后放开这里
            // this.$router.history.go(-1);
          })
          reject(false)
        }
      })
    },

    getCinemaInfo() {
      const mobile = this.$store.state.userInfo.mobile
      const ps = `{"UserCode":"${mobile}","CmdIndex":"101002","cmdArgs":[${this.selectCinema.code}]}`
      api.getPs({ ps: ps }).then((r) => {
        const { Success, data, Message } = r
        if (Success) {
          this.cinemaBase = {
            name: data.baseInfo.CinemaName,
            code: data.baseInfo.CinemaCode,
            cinemaLine: data.baseInfo.CinemaChain,
            user: data.baseInfo.LegalName,
            numberOfCinemas: data.baseInfo.ScreenCount,
            numberOfSeats: data.baseInfo.SeatCount,
          }
          this.warningInfo = data.warningInfo
          this.kpiInfo = data.kpiInfo
        } else {
          Toast(Message)
        }
      })
    },
    changeCinema(c) {
      this.selectCinema = c
      this.getCinemaInfo()
      this.showcinema = false
      localStorage.setItem('selectCinema', JSON.stringify(c))
    },
    goPath(path) {
      this.$router.push(path)
    },
  },
}
</script>

<style scoped lang="less">
#page {
  //border: 1px solid red;
  padding: 0.1rem;
  background-image: linear-gradient(
    to bottom,
    #1666eb 0%,
    #2cdce5 22.99%,
    #f7f7f7 23%
  );

  .selectCinema {
    color: #fff;
  }

  .navbar {
    display: flex;
    flex-direction: row;
    height: 0.8rem;
    align-items: center;
    color: #fff;

    div {
      &:first-child,
      &:nth-child(3) {
        flex: 1;
        text-align: center;
      }

      &:nth-child(2) {
        flex: 5;
        text-align: center;
      }

      p {
        margin: 0;
        padding: 0;
      }

      .selectCinema {
        color: #fff;
      }
    }
  }
}
</style>
